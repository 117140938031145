<template>
  <b-container fluid>
      <h6>Coupons</h6>
  </b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>